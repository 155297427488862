<template>
    <div>
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">

            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"></feather-icon>
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Territory</vs-th>
                <vs-th sort-key="">Canvas Data</vs-th>
                <vs-th sort-key="">Date Details</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key="" v-if="status === 'Unloading'">Status</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="#000099"
                                icon-pack="feather"
                                icon="icon-printer"
                                v-if="status === 'Unloading'"
                                title="Print"
                                style="margin-right: 5px"
                                @click="handlePrint(tr.id)"
                                :disabled="toggleDisabled(tr.status !== '2', 'view')"
                            />
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                v-if="status === 'Unloading'"
                                :disabled="!checkPermission('view')"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id, tr.status)"
                            />

                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                v-if="status === 'Close'"
                                :disabled="!checkPermission('view')"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id)"
                            />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.sales_code }}</div>
                            <hr>
                            <div>Name : {{ tr.sales_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.territory_code }}</div>
                            <hr>
                            <div>Name : {{ tr.territory_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Item Request : {{ tr.req_code }}</div>
                            <hr>
                            <div>Handover : {{ tr.handover_code }}</div>
                            <hr>
                            <div>Unloading : {{ tr.unload_code }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Request Date : {{ tr.request_date }}</div>
                            <hr>
                            <div>Loading Date : {{ (tr.loading_date == '0001-01-01' || tr.loading_date == null) ? '-' : tr.loading_date }}</div>
                            <hr>
                            <div>Unloading Date : {{ tr.unloading_date }}</div>
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.qty_uom }}</vs-td>
                    <vs-td v-if="status === 'Unloading'">{{ (tr.status == '0') ? 'Receive' : (tr.status == '1') ? 'Putaway' : (tr.status == '2') ? 'Complete' : '' }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        territoryId: {
            type: Array,
        },
        warehouseId: {
            type: Array,
        },
        salesId: {
            type: Array,
        },
        status: {
            type: String,
            default: "Unloading",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            currentStat: null,
            table: this.tableDefaultState(),
        };
    },
    mounted() {
        this.$store.dispatch("user/getPermissions", "canvas-item-request");
    },
    methods: {
        checkPermission(stat) {
            return this.$store.getters['user/hasPermissions'](stat);
        },
        toggleDisabled(condition, permission) {
            if (this.checkPermission(permission)) {
                if (condition) {
                    // expected condition?
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.id)
            });

            let salesIds = [];
            this.salesId.forEach(val => {
                salesIds.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_id: warehouseIds,
                territory_id: territoryIds,
                personal_id: salesIds,
                status: this.status,
            }

            this.$vs.loading();
            this.$http.post("/api/v1/canvas/table-unloads", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDetail(id, stat) {
            this.currentStat = stat

            if (this.currentStat == "0") {
                this.$router.push({
                    name: "canvas.item-request-unload-detail-receive",
                    params: { id: id },
                });
            } else {
                this.$router.push({
                    name: "canvas.item-request-unload-detail-putaway",
                    params: { id: id },
                });
            }
        },
        handlePrint(id) {
            const routeData = this.$router.resolve({
                name: "canvas.item-request/print-unloading",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>