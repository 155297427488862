<template>
    <div>
        <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
        >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"></feather-icon>
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Territory</vs-th>
                <vs-th sort-key="">Canvas Data</vs-th>
                <vs-th sort-key="">Date Details</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key="" v-if="status === 'Load-Confirm'">Status</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                v-if="['New', 'Inquiry'].includes(status)"
                                :disabled="!checkPermission('edit')"
                                title="Edit"
                                style="margin-right: 5px"
                                @click="handleEdit(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x-square"
                                v-if="['New', 'Inquiry'].includes(status)"
                                :disabled="!checkPermission('cancel')"
                                title="Cancel"
                                style="margin-right: 5px"
                                @click="handleCancel(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                v-if="['New', 'Inquiry'].includes(status)"
                                :disabled="!checkPermission('create')"
                                title="Confirm"
                                style="margin-right: 5px"
                                @click="handleConfirm(tr.id)"
                            />

                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                v-if="status !== 'New' && status != 'Inquiry'"
                                :disabled="!checkPermission('view')"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id, tr.status, tr.status_picking)"
                            />

                            <vs-button
                                size="small"
                                color="primary"
                                icon-pack="feather"
                                icon="icon-printer"
                                v-if="status === 'Load-Confirm'"
                                :disabled="!checkPermission('view')"
                                title="Print"
                                style="margin-right: 5px"
                                @click="handlePrint(tr.id)"
                            />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.sales_code }}</div>
                            <hr>
                            <div>Name : {{ tr.sales_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.territory_code }}</div>
                            <hr>
                            <div>Name : {{ tr.territory_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Item Request : {{ tr.code }}</div>
                            <hr>
                            <div>Handover : {{ tr.handover_code }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Request Date : {{ tr.date }}</div>
                            <hr>
                            <div>Loading Date : {{ tr.loading_date == '0001-01-01' ? '-' : tr.loading_date }}</div>
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                    <vs-td v-if="status === 'Load-Confirm'">{{ (tr.status == '0' ? 'Confirmed' : 'Process') }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        territoryId: {
            type: Array,
        },
        warehouseId: {
            type: Array,
        },
        salesId: {
            type: Array,
        },
        status: {
            type: String,
            default: "New",
        },
    },
    data() {
        return {
            deleteId: null,
            stats: null,
            stats_picking: null,
            action: null,
            table: this.tableDefaultState(),
        };
    },
    mounted() {
        this.$store.dispatch("user/getPermissions", "canvas-item-request");
    },
    methods: {
        checkPermission(stat) {
            return this.$store.getters['user/hasPermissions'](stat);
        },
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.id)
            });

            let salesIds = [];
            this.salesId.forEach(val => {
                salesIds.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                territory_id: territoryIds,
                warehouse_id: warehouseIds,
                personal_id: salesIds,
                status: this.status,
            }

            this.$vs.loading();
            this.$http.post("/api/v1/canvas/data-table", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        color: "danger",
                        title: "Something went wrong",
                        text: resp.message,
                    });
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                });
            });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(id) {
            this.$router.push({
                name: "canvas.item-request-edit",
                params: { id: id },
                stats: { status: this.status }
            });
        },
        handleCancel(id) {
            this.action = "cancel";
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/v1/canvas/change-stat/" + this.deleteId + "/" + this.action)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                type: "alert",
                                acceptText: `OK`,
                                color: "success",
                                title: `Success`,
                                text: resp.message,
                            });
                            this.getData();
                        } else {
                            this.$vs.dialog({
                                type: "alert",
                                acceptText: `OK`,
                                color: "danger",
                                title: `Something went wrong`,
                                text: resp.message,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.dialog({
                            type: "alert",
                            acceptText: `OK`,
                            color: "danger",
                            title: `Something went wrong`,
                            text: error,
                        });
                    });
                },
            });
        },
        handleConfirm(id) {
            this.action = "confirm";
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to confirm this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/v1/canvas/change-stat/" + this.deleteId + "/" + this.action)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                type: "alert",
                                acceptText: `OK`,
                                color: "success",
                                title: `Success`,
                                text: resp.message,
                            });
                            this.getData();
                        } else {
                            this.$vs.dialog({
                                type: "alert",
                                acceptText: `OK`,
                                color: "danger",
                                title: `Something went wrong`,
                                text: resp.message,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.dialog({
                            type: "alert",
                            acceptText: `OK`,
                            color: "danger",
                            title: `Something went wrong`,
                            text: error,
                        });
                    });
                },
            });
        },
        handleDetail(id, status, status_picking) {
            this.stats = status
            this.stats_picking = status_picking

            if (this.stats == 2) {
                if (this.stats_picking == 1) {
                    this.$router.push({
                        name: "canvas.item-request-detail-on-process",
                        params: { id: id },
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Not picked yet",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            } else {
                this.$router.push({
                    name: "canvas.item-request-detail",
                    params: { id: id },
                });
            }
        },
        handlePrint(id) {
            const routeData = this.$router.resolve({
                name: "canvas.item-request-print",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>